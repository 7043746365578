/*
Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

.payment-field {
	font-size: 1.8em;
}

.payment-field-submitted {
	font-size: 1.8em;
	color: red;
}

.payment-field-accepted {
	font-size: 1.8em;
	color: orange;
}

.payment-field-complete {
	font-size: 1.8em;
	color: green;
}