/*
Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

.transaction-debit {
  color: #FF0000;
  font-size: 1.6em;
  text-align: right;
  font-style: italic;
}

.transaction-credit {
  color: #00AA00;
  font-size: 1.6em;
  text-align: right;
  font-weight: bold;
}

.transaction-account-balance {
  margin-bottom: 30px;
  text-align: right;
}

.transaction-account-amount {
  font-size: 2.5em;
  font-weight: bold;
  padding: 10px;
}